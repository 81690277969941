import React from "react";
import styled from "styled-components";
import { ReactComponent as Base } from "../../assets/svg/base.svg";
import Color from "color";
import { catConfig, CatConfigProperties } from "../../config/catConfig";
import { getConfigFromDna } from "../../utils/getConfigFromDna";

export type CatConfig = { [key in CatConfigProperties]: number };

interface CatPropsConfig {
  config: CatConfig;
  maxWidth?: number;
}

interface CatPropsDna {
  dna: string;
  maxWidth?: number;
}

type CatProps = CatPropsConfig | CatPropsDna;

const CatWrapper = styled.div<{
  maincolor: string;
  maincolordark: string;
  secondarycolor: string;
  patterncolor: string;
  mainbasecolor: string;
  secondarybasecolor: string;
  mainhaircolor: string;
  secondaryhaircolor: string;
  chaincolor: string;
  mainheadphonecolor: string;
  secondaryheadphonecolor: string;
  beakshadowcolor: string;
  beakcolor: string;
  feathercolor: string;
  maineyecolor: string;
  secondaryeyecolor: string;
  maxWidth?: number;
}>`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "")};
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-bottom: 110%;
  }

  .main-fill {
    fill: ${(props) => props.mainbasecolor};
  }
  .main-stroke {
    stroke: ${(props) => props.maincolordark};
  }
  .main-dark-fill {
    fill: ${(props) => props.maincolordark};
  }
  .secondary-fill {
    fill: ${(props) => props.secondarybasecolor};
  }
  .pattern-fill {
    fill: ${(props) => props.patterncolor};
  }
  .main-eye-color {
    fill: ${(props) => props.maineyecolor};
  }
  .secondary-eye-color {
    fill: ${(props) => props.secondaryeyecolor};
  }
  .beakColor {
    fill: ${(props) => props.beakcolor};
  }
  .beakShadow {
    fill: ${(props) => props.beakshadowcolor};
  }
  .chainColor {
    fill: ${(props) => props.chaincolor};
  }
  .main-hair-color {
    fill: ${(props) => props.mainhaircolor};
  }
  .secondary-hair-color {
    fill: ${(props) => props.secondaryhaircolor};
  }
  .patternColor {
    fill: ${(props) => props.patterncolor};
  }
  .main-phones-color {
    fill: ${(props) => props.mainheadphonecolor};
  }
  .secondary-phones-color {
    fill: ${(props) => props.secondaryheadphonecolor};
  }
  .feather-fill {
    fill: ${(props) => props.feathercolor};
  }
`;

const DefaultComponent = () => <div />;

export const Cat = (props: CatProps) => {
  const config = "config" in props ? props.config : getConfigFromDna(props.dna);
  const {
    mainColor,
    secondaryColor,
    patternColor,
    mainEyeColor,
    secondaryEyeColor,
    mainBaseColor,
    secondaryBaseColor,
    mainHairColor,
    secondaryHairColor,
    chainColor,
    mainHeadphoneColor,
    secondaryHeadphoneColor,
    beakShadowColor,
    beakColor,
    featherColor,
    eyes,
    decoration,
    beak,
    feathers,
    pattern,
    hidden2,
  } = config;
  const { maxWidth } = props;

  const PatternComponent = catConfig.properties.pattern.variations[pattern] || DefaultComponent;
  const EyesComponent = catConfig.properties.eyes.variations[eyes] || DefaultComponent;
  const DecorationComponent = catConfig.properties.decoration.variations[decoration] || DefaultComponent;
  const BeakComponent = catConfig.properties.beak.variations[beak] || DefaultComponent;
  const FeathersComponent = catConfig.properties.feathers.variations[feathers] || DefaultComponent;

  const mainColorHex = Color(catConfig.properties.mainColor.variations[mainColor]).toString();
  const mainColorDarkHex = Color(catConfig.properties.mainColor.variations[mainColor]).darken(0.65).saturate(0.5).toString();
  const secondaryColorHex = Color(catConfig.properties.secondaryColor.variations[secondaryColor]).toString();
  const patternColorHex = Color(catConfig.properties.patternColor.variations[patternColor]).toString();
  const mainBaseColorHex = Color(catConfig.properties.mainBaseColor.variations[mainBaseColor]).toString();
  const secondaryBaseColorHex = Color(catConfig.properties.secondaryBaseColor.variations[secondaryBaseColor]).toString();
  const mainHairColorHex = Color(catConfig.properties.mainHairColor.variations[mainHairColor]).toString();
  const secondaryHairColorHex = Color(catConfig.properties.secondaryHairColor.variations[secondaryHairColor]).toString();
  const chainColorHex = Color(catConfig.properties.chainColor.variations[chainColor]).toString();
  const mainHeadphoneColorHex = Color(catConfig.properties.mainHeadphoneColor.variations[mainHeadphoneColor]).toString();
  const secondaryHeadphoneColorHex = Color(catConfig.properties.secondaryHeadphoneColor.variations[secondaryHeadphoneColor]).toString();
  const beakShadowColorHex = Color(catConfig.properties.beakShadowColor.variations[beakShadowColor]).toString();
  const beakColorHex = Color(catConfig.properties.beakColor.variations[beakColor]).toString();
  const featherColorHex = Color(catConfig.properties.featherColor.variations[featherColor]).toString();
  const mainEyeColorHex = Color(catConfig.properties.mainEyeColor.variations[mainEyeColor]).toString();
  const secondaryEyeColorHex = Color(catConfig.properties.secondaryEyeColor.variations[secondaryEyeColor]).toString();

  const showPattern = hidden2 > 5;

  console.log('Configuration:', config);
  console.log('BeakComponent:', BeakComponent);
  console.log('EyesComponent:', EyesComponent);

  return (
    <CatWrapper
      maincolor={mainColorHex}
      maincolordark={mainColorDarkHex}
      secondarycolor={secondaryColorHex}
      patterncolor={patternColorHex}
      mainbasecolor={mainBaseColorHex}
      secondarybasecolor={secondaryBaseColorHex}
      mainhaircolor={mainHairColorHex}
      secondaryhaircolor={secondaryHairColorHex}
      chaincolor={chainColorHex}
      mainheadphonecolor={mainHeadphoneColorHex}
      secondaryheadphonecolor={secondaryHeadphoneColorHex}
      beakshadowcolor={beakShadowColorHex}
      beakcolor={beakColorHex}
      feathercolor={featherColorHex}
      maineyecolor={mainEyeColorHex}
      secondaryeyecolor={secondaryEyeColorHex}
      maxWidth={maxWidth}
    >
      <Base style={{ position: "absolute", top: 0, left: 0 }} />
      {FeathersComponent && <FeathersComponent style={{ position: "absolute", top: 0, left: 0 }} />}
      {showPattern && <PatternComponent style={{ position: "absolute", top: 0, left: 0 }} />}
      {DecorationComponent && <DecorationComponent style={{ position: "absolute", top: 0, left: 0 }} />}
      {EyesComponent && <EyesComponent style={{ position: "absolute", top: 0, left: 0 }} />}
      {BeakComponent && <BeakComponent style={{ position: "absolute", top: 0, left: 0 }} />}
    </CatWrapper>
  );
};
