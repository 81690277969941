export const adjectives = [
  'average',
  'big',
  'colossal',
  'chonky',
  'giant',
  'gigantic',
  'great',
  'huge',
  'immense',
  'large',
  'little',
  'long',
  'mammoth',
  'massive',
  'miniature',
  'petite',
  'puny',
  'short',
  'small',
  'tall',
  'tiny',
  'breezy',
  'broken',
  'bumpy',
  'chilly',
  'cold',
  'cool',
  'creepy',
  'crooked',
  'cuddly',
  'curly',
  'damaged',
  'damp',
  'dirty',
  'dusty',
  'filthy',
  'fluffy',
  'high',
  'hollow',
  'low',
  'shallow',
  'skinny',
  'square',
  'straight',
  'ancient',
  'fast',
  'late',
  'modern',
  'old',
  'quick',
  'rapid',
  'slow',
  'swift',
  'young',
  'empty',
  'heavy',
  'light',
  'loud',
  'melodic',
  'mute',
  'noisy',
  'quiet',
  'raspy',
  'shrill',
  'silent',
  'soft',
  'whispering',
  'bitter',
  'delicious',
  'fresh',
  'juicy',
  'ripe',
  'rotten',
  'salty',
  'sour',
  'spicy',
  'stale',
  'sticky',
  'strong',
  'sweet',
  'tasteless',
  'tasty',
  'thirsty',
  'fluttering',
  'fuzzy',
  'greasy',
  'grubby',
  'hard',
  'hot',
  'icy',
  'loose',
  'melted',
  'plastic',
  'prickly',
  'rough',
  'scattered',
  'shaggy',
  'shaky',
  'sharp',
  'shivering',
  'silky',
  'slimy',
  'slippery',
  'smooth',
  'solid',
  'steady',
  'tender',
  'tight',
  'uneven',
  'weak',
  'wooden',
  'angry',
  'annoyed',
  'anxious',
  'arrogant',
  'ashamed',
  'awful',
  'bad',
  'bewildered',
  'bored',
  'combative',
  'condemned',
  'confused',
  'cruel',
  'dangerous',
  'defeated',
  'defiant',
  'depressed',
  'disgusted',
  'disturbed',
  'eerie',
  'embarrassed',
  'envious',
  'evil',
  'fierce',
  'foolish',
  'frantic',
  'frightened',
  'hungry',
  'jealous',
  'lonely',
  'mysterious',
  'naughty',
  'nervous',
  'obnoxious',
  'outrageous',
  'panicky',
  'repulsive',
  'scary',
  'scornful',
  'selfish',
  'sore',
  'tense',
  'terrible',
  'thoughtless',
  'tired',
  'troubled',
  'upset',
  'uptight',
  'weary',
  'wicked',
  'worried',
  'agreeable',
  'amused',
  'brave',
  'calm',
  'charming',
  'cheerful',
  'comfortable',
  'cooperative',
  'courageous',
  'delightful',
  'determined',
  'eager',
  'elated',
  'enchanting',
  'encouraging',
  'energetic',
  'enthusiastic',
  'excited',
  'exuberant',
  'fair',
  'faithful',
  'fantastic',
  'fine',
  'friendly',
  'funny',
  'gentle',
  'glorious',
  'good',
  'happy',
  'healthy',
  'helpful',
  'hilarious',
  'jolly',
  'joyous',
  'kind',
  'lively',
  'lovely',
  'lucky',
  'obedient',
  'perfect',
  'pleasant',
  'proud',
  'relieved',
  'silly',
  'smiling',
  'splendid',
  'successful',
  'thoughtful',
  'victorious',
  'vivacious',
  'witty',
  'wonderful',
  'zealous',
  'zany',
  'new',
  'different',
  'local',
  'social',
  'important',
  'national',
  'british',
  'right',
  'early',
  'possible',
  'political',
  'able',
  'general',
  'full',
  'public',
  'available',
  'sure',
  'clear',
  'major',
  'only',
  'likely',
  'real',
  'international',
  'special',
  'difficult',
  'certain',
  'open',
  'whole',
  'free',
  'easy',
  'european',
  'central',
  'similar',
  'human',
  'common',
  'necessary',
  'single',
  'personal',
  'private',
  'poor',
  'foreign',
  'simple',
  'concerned',
  'american',
  'english',
  'wrong',
  'royal',
  'natural',
  'nice',
  'french',
  'red',
  'normal',
  'serious',
  'previous',
  'total',
  'prime',
  'significant',
  'industrial',
  'sorry',
  'dead',
  'specific',
  'appropriate',
  'top',
  'soviet',
  'basic',
  'original',
  'popular',
  'professional',
  'direct',
  'dark',
  'ready',
  'green',
  'useful',
  'effective',
  'western',
  'scottish',
  'german',
  'independent',
  'deep',
  'interesting',
  'considerable',
  'involved',
  'physical',
  'left',
  'existing',
  'responsible',
  'complete',
  'blue',
  'extra',
  'essential',
  'beautiful',
  'civil',
  'primary',
  'obvious',
  'future',
  'positive',
  'senior',
  'nuclear',
  'annual',
  'relevant',
  'rich',
  'safe',
  'regional',
  'practical',
  'official',
  'separate',
  'key',
  'chief',
  'regular',
  'active',
  'powerful',
  'complex',
  'standard',
  'impossible',
  'warm',
  'domestic',
  'actual',
  'united',
  'technical',
  'ordinary',
  'cheap',
  'strange',
  'excellent',
  'potential',
  'northern',
  'religious',
  'very',
  'famous',
  'proper',
  'formal',
  'limited',
  'conservative',
  'usual',
  'rural',
  'substantial',
  'bright',
  'leading',
  'reasonable',
  'immediate',
  'suitable',
  'equal',
  'detailed',
  'working',
  'democratic',
  'growing',
  'sufficient',
  'scientific',
  'eastern',
  'irish',
  'expensive',
  'educational',
  'mental',
  'critical',
  'increased',
  'familiar',
  'unlikely',
  'double',
  'historical',
  'thin',
  'daily',
  'southern',
  'wild',
  'urban',
  'liberal',
  'busy',
  'russian',
  'careful',
  'clean',
  'attractive',
  'japanese',
  'vital',
  'thick',
  'alternative',
  'elderly',
  'rare',
  'capable',
  'brief',
  'grand',
  'typical',
  'grey',
  'constant',
  'vast',
  'surprised',
  'ideal',
  'academic',
  'minor',
  'pleased',
  'severe',
  'corporate',
  'negative',
  'permanent',
  'brown',
  'fundamental',
  'odd',
  'crucial',
  'used',
  'criminal',
  'contemporary',
  'sick',
  'unique',
  'secondary',
  'parliamentary',
  'african',
  'unknown',
  'subsequent',
  'alive',
  'guilty',
  'enormous',
  'communist',
  'yellow',
  'unusual',
  'tough',
  'dear',
  'alright',
  'italian',
  'principal',
  'efficient',
  'chinese',
  'conventional',
  'willing',
  'dramatic',
  'golden',
  'temporary',
  'federal',
  'keen',
  'flat',
  'indian',
  'pale',
  'welsh',
  'dependent',
  'firm',
  'competitive',
  'armed',
  'radical',
  'acceptable',
  'sensitive',
  'pure',
  'global',
  'emotional',
  'sad',
  'secret',
  'adequate',
  'fixed',
  'remarkable',
  'comprehensive',
  'surprising',
  'brilliant',
  'maximum',
  'absolute',
  'electronic',
  'visual',
  'electric',
  'spanish',
  'literary',
  'supreme',
  'chemical',
  'genuine',
  'exciting',
  'stupid',
  'advanced',
  'classical',
  'fit',
  'favourite',
  'socialist',
  'widespread',
  'confident',
  'opposite',
  'distinct',
  'mad',
  'consistent',
  'stable',
  'satisfied',
  'conscious',
  'developing',
  'strategic',
  'holy',
  'dominant',
  'theoretical',
  'outstanding',
  'pink',
  'pretty',
  'minimum',
  'honest',
  'impressive',
  'related',
  'residential',
  'extraordinary',
  'plain',
  'visible',
  'accurate',
  'distant',
  'still',
  'greek',
  'complicated',
  'musical',
  'precise',
  'live',
  'monetary',
  'round',
  'psychological',
  'violent',
  'unemployed',
  'inevitable',
  'junior',
  'sensible',
  'grateful',
  'mean',
  'entitled',
  'delighted',
  'occasional',
  'evident',
  'desperate',
  'fellow',
  'universal',
  'classic',
  'intellectual',
  'victorian',
  'ultimate',
  'creative',
  'lost',
  'medieval',
  'clever',
  'linguistic',
  'convinced',
  'judicial',
  'raw',
  'sophisticated',
  'sleepy',
  'vulnerable',
  'illegal',
  'revolutionary',
  'australian',
  'native',
  'imperial',
  'strict',
  'wise',
  'informal',
  'flexible',
  'frequent',
  'experimental',
  'spiritual',
  'intense',
  'rational',
  'generous',
  'inadequate',
  'prominent',
  'logical',
  'modest',
  'dutch',
  'electrical',
  'valid',
  'gross',
  'automatic',
  'reliable',
  'mutual',
  'liable',
  'multiple',
  'curious',
  'marginal',
  'distinctive',
  'urgent',
  'presidential',
  'controversial',
  'everyday',
  'organic',
  'desirable',
  'innocent',
  'improved',
  'exclusive',
  'marked',
  'experienced',
  'unexpected',
  'superb',
  'disappointed',
  'capitalist',
  'romantic',
  'naked',
  'reluctant',
  'magnificent',
  'convenient',
  'established',
  'uncertain',
  'artificial',
  'diplomatic',
  'tremendous',
  'mechanical',
  'institutional',
  'known',
  'functional',
  'straightforward',
  'superior',
  'digital',
  'spectacular',
  'unhappy',
  'unfair',
  'aggressive',
  'abstract',
  'asian',
  'intelligent',
  'explicit',
  'nasty',
  'just',
  'faint',
  'ridiculous',
  'amazing',
  'comparable',
  'successive',
  'realistic',
  'decent',
  'unnecessary',
  'flying',
  'random',
  'influential',
  'dull',
  'neat',
  'marvellous',
  'crazy',
  'secure',
  'skilled',
  'subtle',
  'elegant',
  'lesser',
  'parallel',
  'intensive',
  'casual',
  'tropical',
  'partial',
  'preliminary',
  'concrete',
  'alleged',
  'vertical',
  'delicate',
  'mild',
  'excessive',
  'progressive',
  'exceptional',
  'integrated',
  'striking',
  'continental',
  'okay',
  'harsh',
  'handsome',
  'chronic',
  'compulsory',
  'interim',
  'objective',
  'magic',
  'systematic',
  'fun',
  'horrible',
  'primitive',
  'fascinating',
  'ideological',
  'metropolitan',
  'surrounding',
  'peaceful',
  'premier',
  'hostile',
  'precious',
  'gay',
  'impressed',
  'provincial',
  'smart',
  'endless',
  'isolated',
  'drunk',
  'dynamic',
  'boring',
  'forthcoming',
  'unfortunate',
  'definite',
  'super',
  'notable',
  'indirect',
  'stiff',
  'wealthy',
  'awkward',
  'neutral',
  'artistic',
  'content',
  'mature',
  'colonial',
  'ambitious',
  'magnetic',
  'verbal',
  'legitimate',
  'sympathetic',
  'empirical',
  'vague',
  'shocked',
  'mid',
  'worthwhile',
  'qualified',
  'missing',
  'blank',
  'absent',
  'favourable',
  'polish',
  'developed',
  'profound',
  'dreadful',
  'rigid',
  'coastal',
  'peculiar',
  'ugly',
  'swiss',
  'crude',
  'canadian',
  'bold',
  'relaxed',
  'running',
  'uncomfortable',
  'productive',
  'beneficial',
  'minimal',
  'mobile',
  'turkish',
  'orange',
  'passive',
  'suspicious',
  'overwhelming',
  'symbolic',
  'registered',
  'neighbouring',
  'irrelevant',
  'patient',
  'compact',
  'profitable',
  'rival',
  'loyal',
  'moderate',
  'distinguished',
  'noble',
  'insufficient',
  'eligible',
  'varying',
  'molecular',
  'olympic',
  'linear',
  'prospective',
  'printed',
  'parental',
  'diverse',
  'elaborate',
  'furious',
  'fiscal',
  'burning',
  'useless',
  'semantic',
  'inherent',
  'philosophical',
  'deliberate',
  'awake',
  'variable',
  'promising',
  'unpleasant',
  'sacred',
  'selective',
  'inclined',
  'hidden',
  'worthy',
  'intermediate',
  'protective',
  'fortunate',
  'slim',
  'defensive',
  'divine',
  'stuck',
  'driving',
  'invisible',
  'misleading',
  'circular',
  'mathematical',
  'inappropriate',
  'liquid',
  'persistent',
  'solar',
  'doubtful',
  'manual',
  'incredible',
  'devoted',
  'tragic',
  'respectable',
  'optimistic',
  'convincing',
  'unacceptable',
  'decisive',
  'competent',
  'toxic',
  'redundant',
  'integral',
  'probable',
  'amateur',
  'fond',
  'territorial',
  'horizontal',
  'miserable',
  'resident',
  'polite',
  'scared',
  'marxist',
  'gothic',
  'civilian',
  'instant',
  'lengthy',
  'adverse',
  'korean',
  'unconscious',
  'anonymous',
  'aesthetic',
  'orthodox',
  'static',
  'unaware',
  'costly',
  'fashionable',
  'causal',
  'compatible',
  'wee',
  'implicit',
  'dual',
  'ok',
  'exotic',
  'purple',
  'cautious',
  'visiting',
  'aggregate',
  'ethical',
  'teenage',
  'disastrous',
  'confidential',
  'underground',
  'thorough',
  'grim',
  'autonomous',
  'atomic',
  'frozen',
  'colourful',
  'coherent',
  'shy',
  'balanced',
  'arbitrary',
  'worrying',
  'weird',
  'unchanged',
  'rolling',
  'formidable',
  'gradual',
  'accused',
  'supporting',
  'renewed',
  'excessive',
  'retired',
  'chosen',
  'outdoor',
  'embarrassing',
  'preferred',
  'bizarre',
  'appalling',
  'imaginative',
  'mighty',
  'puzzled',
  'worldwide',
  'sunny',
  'eldest',
  'eventual',
  'spontaneous',
  'vivid',
  'rude',
  'innovative',
  'conceptual',
  'unwilling',
  'meaningful',
  'disturbing',
  'brainy',
  'breakable',
  'fragile',
  'helpless',
  'inquisitive',
  'sleepy',
  'tame',
  'uninterested',
  'wandering',
  'adorable',
  'alert',
  'blushing',
  'cloudy',
  'colorful',
  'crowded',
  'cute',
  'drab',
  'fancy',
  'glamorous',
  'gleaming',
  'graceful',
  'grotesque',
  'homely',
  'misty',
  'motionless',
  'muddy',
  'poised',
  'quaint',
  'shiny',
  'smoggy',
  'sparkling',
  'spotless',
  'stormy',
  'unsightly',
  'better',
  'clumsy',
  'dizzy',
  'frail',
  'gorgeous',
  'grumpy',
  'itchy',
  'jittery',
  'lazy',
  'magenta',
  'many',
  'mushy',
  'nosy',
  'nutty',
  'quickest',
  'rainy',
  'roasted',
  'robust',
  'scrawny',
  'stingy',
  'striped',
  'tart',
  'tan',
  'testy',
  'tricky',
  'wasteful',
  'yummy',
  'cringe',
  'mister',
  'miss',
  'doctor',
  'lord',
  'professor',
  'hillbilly',
  'closeted',
  'wannabe',
  'horny',
  'burning',
  'likeable',
  'guilty',
  'infamous',
  'doc',
  'kid',
  'lil',
  'meek',
  'dimebag',
  'the',
  'metal',
  'punk',
  'classy',
  'sassy',
  'dizzy',
  'real',
  'this',
  'el',
  'the',
  'boss',
  'Ace',
  'Buddy',
  'Champ',
  'Chief',
  'Duke',
  'Flash',
  'Hoss',
  'King',
  'Maverick',
  'Rocky',
  'Slim',
  'Spike',
  'Tex',
  'Tiger',
  'Boss',
  'Doc',
  'Jazz',
  'Rex',
  'Scout',
  'Zeke',
  'Babe',
  'Bella',
  'Bubbles',
  'Dolly',
  'Gigi',
  'Honey',
  'Jazz',
  'Kit',
  'Lulu',
  'Mimi',
  'Queen',
  'Ruby',
  'Sparkle',
  'Star',
  'Sunny',
  'Trixie',
  'Angel',
  'Jade',
  'Sky',
  'Venus',
  'prince',
  'king',
  'Señor',
  'Monsieur',
  'Herr',
  'Señorita',
  'Mademoiselle',
  'Fräulein',
  'based',
  'best',
  'ripped',
  'chill',
  'Cool',
  'Awesome',
  'Lit',
  'Dope',
  'Sick',
  'Epic',
  'Chill',
  'Rad',
  'Legit',
  'Snazzy',
  'Fly',
  'Sweet',
  'Tight',
  'Bangin',
  'Boss',
  'Funky',
  'Gnarly',
  'Groovy',
  'Hip',
  'Phat',
  'Swaggy',
  'Wicked',
  'Wild',
  'Fresh',
  'Stellar',
  'noob',
  'galaxy',
  'grounded',
  'free',
  'curious',
  'envious',
  'hopeful',
  'Quirky',
  'Wacky',
  'Zany',
  'Goofy',
  'Nutty',
  'Silly',
  'Loopy',
  'Hilarious',
  'Ridiculous',
  'Bonkers',
  'Comical',
  'Bizarre',
  'Whimsical',
  'Clownish',
  'Absurd',
  'Ludicrous',
  'Daft',
  'Kooky',
  'Outlandish',
  'Preposterous',
  'Jolly',
  'Jokey',
  'Slapstick',
  'Farcical',
  'Lighthearted',
  'smokey',
  'cokey',
  'indignant',
  'hammerin',
  'hefty',
  'lefty',
  'flash',
  'round',
  'flying',
  'pudgy',
  'macho',
  'hitman',
  'wizard',
  'jet',
  'baby',
  'stretch',
  'rusty',
  'admiral',
  'rocket',
  'tiger',
  'nails',
  'spider',
  'goose',
  'iron',
  'iceman',
  'bear',
  'boomboom',
  'catfish',
  'country',
  'october',
  'shoeless',
  'papi',
  'Grandmama',
  'juice',
  'air',
  'broadway',
  'hehateme',
  'junkyard',
  'captain',
  'tootall',
  'magic',
  'splendid',
  'crazylegs',
  'duke',
  'pistol',
  'sugar',
  'refrigerator',
  'joltin',
  'hyper',
  'dancin',
];