import React from "react";
import { AppBar, Toolbar, Link } from "@material-ui/core";
import { useState } from 'react';
import styles from "./Navbar.module.css";
import { ConnectButton } from "../ConnectWalletButton/ConnectWalletButton";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { Link as RouterLink } from "react-router-dom";

const Brand = styled.span`
  flex-grow: 1;
  font-size: 2.3rem;
  line-height: 1;
  color: white;
`;

const Buttons = styled.div`
  & > * {
    margin: 4px;
  }
`;
/**
 * Navigation bar that shows up on all pages.
 * Rendered in _app.tsx file above the page content.
 */
export function NavigationBar() {
  // adding the states 
  const [isActive, setIsActive] = useState(false);
  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false)
  }
  return (
    <div className="App">
      <header className="App-header">
        <nav className={`${styles.navbar}`}>
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive}>
              <a href='/' className={`${styles.navLink}`}>Home</a>
            </li>
            <li onClick={removeActive}>
              <a href='/catalogue' className={`${styles.navLink}`}>List</a>
            </li>
            <li onClick={removeActive}>
              <a href='/market' className={`${styles.navLink}`}>Market</a>
            </li>
            <li onClick={removeActive}>
              <a href='/my-crows' className={`${styles.navLink}`}>My Crows</a>
            </li>
            <li onClick={removeActive}>
              <a href='/factory' className={`${styles.navLink}`}>Factory</a>
            </li>
          </ul>
          <Buttons>
          <ConnectButton />
        </Buttons>
          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>
      </header>
    </div>
  );
}
export default NavigationBar;
;