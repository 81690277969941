import React from "react";
import { CatFactory } from "../components/CatFactory/CatFactory";

export const Factory = () => {
  return (
    <div>
      <CatFactory />
    </div>
  );
};
