export const CatPreset = {
  ErrorCat: "4109031022288877",
  UnAuthorizedCat: "0046003274779473",
  CuteCat: "2038444364911085",
  CoolCat: "1561253421617463",
  FancyCat: "2900397930256857",
  SpaceCat: "9520612801289657",
  GirlyCat: "3908550096369189",
  WeirdCat: "5688959649746782",
};
