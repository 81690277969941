import React, { useState, useEffect } from "react";
import { Cat } from "../Cat/Cat";
import {
  Grid,
  Slider,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { catConfig } from "../../config/catConfig";
import { getDnaFromConfig } from "../../utils/getDnaFromConfig";
import { useKittyCoreContract } from "../../hooks/useContract";
import { useFunction } from "../../hooks/useFunction";
import { parseEther } from "@ethersproject/units";

export const CatFactory = () => {
  const kittyCore = useKittyCoreContract();
  const buyKitty = useFunction(kittyCore, "createGen0Kitty");

  const [mainColor, setMainColor] = useState(0);
  const [secondaryColor, setSecondaryColor] = useState(0);
  const [patternColor, setPatternColor] = useState(0);
  const [mainBaseColor, setMainBaseColor] = useState(0);
  const [secondaryBaseColor, setSecondaryBaseColor] = useState(0);
  const [mainHairColor, setMainHairColor] = useState(0);
  const [secondaryHairColor, setSecondaryHairColor] = useState(0);
  const [chainColor, setChainColor] = useState(0);
  const [mainHeadphoneColor, setMainHeadphoneColor] = useState(0);
  const [secondaryHeadphoneColor, setSecondaryHeadphoneColor] = useState(0);
  const [beakShadowColor, setBeakShadowColor] = useState(0);
  const [beakColor, setBeakColor] = useState(0);
  const [featherColor, setFeatherColor] = useState(0);
  const [mainEyeColor, setMainEyeColor] = useState(0);
  const [secondaryEyeColor, setSecondaryEyeColor] = useState(0);

  const [pattern, setPattern] = useState(0);
  const [eyes, setEyes] = useState(0);
  const [decoration, setDecoration] = useState(0);
  const [beak, setBeak] = useState(0);
  const [feathers, setFeathers] = useState(0);
  const [hidden1, setHidden1] = useState(0);
  const [hidden2, setHidden2] = useState(0);
  const [hidden3, setHidden3] = useState(0);
  const [bonus1, setBonus1] = useState(0);
  const [bonus2, setBonus2] = useState(0);

  useEffect(() => {
    handleRandomize();
  }, []);

  const handleRandomize = () => {
    setMainColor(
      Math.floor(
        Math.random() * catConfig.properties.mainColor.variations.length
      )
    );
    setSecondaryColor(
      Math.floor(
        Math.random() * catConfig.properties.secondaryColor.variations.length
      )
    );
    setPatternColor(
      Math.floor(
        Math.random() * catConfig.properties.patternColor.variations.length
      )
    );
    //NEW COLORS
    setMainBaseColor(
      Math.floor(
        Math.random() * catConfig.properties.mainBaseColor.variations.length
      )
    );
    setSecondaryBaseColor(
      Math.floor(
        Math.random() * catConfig.properties.secondaryBaseColor.variations.length
      )
    );
    setMainHairColor(
      Math.floor(
        Math.random() * catConfig.properties.mainHairColor.variations.length
      )
    );
    setSecondaryHairColor(
      Math.floor(
        Math.random() * catConfig.properties.secondaryHairColor.variations.length
      )
    );
    setChainColor(
      Math.floor(
        Math.random() * catConfig.properties.chainColor.variations.length
      )
    );
    setMainHeadphoneColor(
      Math.floor(
        Math.random() * catConfig.properties.mainHeadphoneColor.variations.length
      )
    );
    setSecondaryHeadphoneColor(
      Math.floor(
        Math.random() * catConfig.properties.secondaryHeadphoneColor.variations.length
      )
    );
    setBeakShadowColor(
      Math.floor(
        Math.random() * catConfig.properties.beakShadowColor.variations.length
      )
    );
    setBeakColor(
      Math.floor(
        Math.random() * catConfig.properties.beakColor.variations.length
      )
    );
    setFeatherColor(
      Math.floor(
        Math.random() * catConfig.properties.featherColor.variations.length
      )
    );
    setMainEyeColor(
      Math.floor(
        Math.random() * catConfig.properties.mainEyeColor.variations.length
      )
    );
    setSecondaryEyeColor(
      Math.floor(
        Math.random() * catConfig.properties.secondaryEyeColor.variations.length
      )
    );

    setPattern(
      Math.floor(Math.random() * catConfig.properties.pattern.variations.length)
    );
    setEyes(
      Math.floor(Math.random() * catConfig.properties.eyes.variations.length)
    );
    setDecoration(
      Math.floor(
        Math.random() * catConfig.properties.decoration.variations.length
      )
    );
    setBeak(
      Math.floor(
        Math.random() * catConfig.properties.beak.variations.length
      )
    );
    setFeathers(
      Math.floor(
        Math.random() * catConfig.properties.feathers.variations.length
      )
    );
    setHidden1(
      Math.floor(Math.random() * catConfig.properties.hidden1.variations.length)
    );
    setHidden2(
      Math.floor(Math.random() * catConfig.properties.hidden2.variations.length)
    );
    setHidden3(
      Math.floor(Math.random() * catConfig.properties.hidden3.variations.length)
    );
    setBonus1(
      Math.floor(Math.random() * catConfig.properties.bonus1.variations.length)
    );
    setBonus2(
      Math.floor(Math.random() * catConfig.properties.bonus2.variations.length)
    );
  };

  const newCatConfig = {
    mainColor,
    secondaryColor,
    patternColor,
    mainBaseColor,
    secondaryBaseColor,
    mainHairColor,
    secondaryHairColor,
    chainColor,
    mainHeadphoneColor,
    secondaryHeadphoneColor,
    beakShadowColor,
    beakColor,
    featherColor,
    mainEyeColor,
    secondaryEyeColor,
    pattern,
    eyes,
    decoration,
    beak,
    feathers,
    hidden1,
    hidden2,
    hidden3,
    bonus1,
    bonus2,
  };
  const catDna = getDnaFromConfig(newCatConfig);

  const handleCreate = async () => {
    console.log("handle create");
    buyKitty.call({
      args: [catDna],
      value: parseEther('169'),
      afterResponse: handleRandomize,
    });
  };

  return (
    <Grid container>
      <Grid item container xs justifyContent="center" alignItems="center">
        <Cat config={newCatConfig} maxWidth={400} />
      </Grid>
      <Grid item xs>
        <Card>
          <CardHeader title="Create a Gen0 crow (169 CRO)" subheader={`dna: ${catDna}`} />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs>
                <Typography gutterBottom>Base color</Typography>
                <Slider
                  value={mainBaseColor}
                  onChange={(event, value) => setMainBaseColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.mainBaseColor.variations.length - 1}
                />
                <Typography gutterBottom>Secondary color</Typography>
                <Slider
                  value={secondaryBaseColor}
                  onChange={(event, value) =>
                    setSecondaryBaseColor(value as number)
                  }
                  step={1}
                  min={0}
                  max={
                    catConfig.properties.secondaryBaseColor.variations.length - 1
                  }
                />
                <Typography gutterBottom>Eye color</Typography>
                <Slider
                  value={mainEyeColor}
                  onChange={(event, value) => setMainEyeColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.mainEyeColor.variations.length - 1}
                />
                <Typography gutterBottom>Inner eye color</Typography>
                <Slider
                  value={secondaryEyeColor}
                  onChange={(event, value) => setSecondaryEyeColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.secondaryEyeColor.variations.length - 1}
                />
                <Typography gutterBottom>Pattern color</Typography>
                <Slider
                  value={patternColor}
                  onChange={(event, value) => setPatternColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.patternColor.variations.length - 1}
                />
                <Typography gutterBottom>Hair color</Typography>
                <Slider
                  value={mainHairColor}
                  onChange={(event, value) => setMainHairColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.mainHairColor.variations.length - 1}
                />
                <Typography gutterBottom>Secondary hair color</Typography>
                <Slider
                  value={secondaryHairColor}
                  onChange={(event, value) => setSecondaryHairColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.secondaryHairColor.variations.length - 1}
                />
                <Typography gutterBottom>Chain color</Typography>
                <Slider
                  value={chainColor}
                  onChange={(event, value) => setChainColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.chainColor.variations.length - 1}
                />
                <Typography gutterBottom>Heaphone color</Typography>
                <Slider
                  value={mainHeadphoneColor}
                  onChange={(event, value) => setMainHeadphoneColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.mainHeadphoneColor.variations.length - 1}
                />
                <Typography gutterBottom>Beak color</Typography>
                <Slider
                  value={beakColor}
                  onChange={(event, value) => setBeakColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.beakColor.variations.length - 1}
                />
                <Typography gutterBottom>Secondary beak color</Typography>
                <Slider
                  value={beakShadowColor}
                  onChange={(event, value) => setBeakShadowColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.beakShadowColor.variations.length - 1}
                />
                <Typography gutterBottom>Feathers color</Typography>
                <Slider
                  value={featherColor}
                  onChange={(event, value) => setFeatherColor(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.featherColor.variations.length - 1}
                />
              </Grid>
              <Grid item xs>
                <Typography gutterBottom>Pattern</Typography>
                <Slider
                  value={pattern}
                  onChange={(event, value) => setPattern(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.pattern.variations.length - 1}
                />
                <Typography gutterBottom>Eyes</Typography>
                <Slider
                  value={eyes}
                  onChange={(event, value) => setEyes(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.eyes.variations.length - 1}
                />
                <Typography gutterBottom>Decoration</Typography>
                <Slider
                  value={decoration}
                  onChange={(event, value) => setDecoration(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.decoration.variations.length - 1}
                />
                <Typography gutterBottom>Beak</Typography>
                <Slider
                  value={beak}
                  onChange={(event, value) => setBeak(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.beak.variations.length - 1}
                />
                <Typography gutterBottom>Feathers</Typography>
                <Slider
                  value={feathers}
                  onChange={(event, value) => setFeathers(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.feathers.variations.length - 1}
                />
                <Typography gutterBottom>Hidden1</Typography>
                <Slider
                  value={hidden1}
                  onChange={(event, value) => setHidden1(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.hidden1.variations.length - 1}
                />
                <Typography gutterBottom>Hidden2</Typography>
                <Slider
                  value={hidden2}
                  onChange={(event, value) => setHidden2(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.hidden2.variations.length - 1}
                />
                <Typography gutterBottom>Hidden3</Typography>
                <Slider
                  value={hidden3}
                  onChange={(event, value) => setHidden3(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.hidden3.variations.length - 1}
                />
                <Typography gutterBottom>Bonus 1</Typography>
                <Slider
                  value={bonus1}
                  onChange={(event, value) => setBonus1(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.bonus1.variations.length - 1}
                />
                <Typography gutterBottom>Bonus 2</Typography>
                <Slider
                  value={bonus2}
                  onChange={(event, value) => setBonus2(value as number)}
                  step={1}
                  min={0}
                  max={catConfig.properties.bonus2.variations.length - 1}
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Button onClick={handleRandomize}>Randomize</Button>
            <Button onClick={handleCreate} color="primary" variant="contained">
              Add crow
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
