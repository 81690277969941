import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ContractInterface } from "@ethersproject/contracts";
import kittyCoreData from "../assets/contracts/KittyCore.json";
import marketPlaceData from "../assets/contracts/MarketPlace.json";

// Define the type for the network objects
interface Network {
  address: string;
}

interface Networks {
  [key: string]: Network;
}

interface ContractData {
  abi: ContractInterface;
  networks: Networks;
}

const kittyCore: ContractData = kittyCoreData as unknown as ContractData;
const marketPlace: ContractData = marketPlaceData as unknown as ContractData;

// Utility function to get contract address based on chainId
const getNetworkAddress = (networks: Networks, chainId: number | undefined): string | null => {
  if (chainId === 1337) {
    const networksValues = Object.values(networks);
    const lastNetwork = networksValues[networksValues.length - 1];
    return lastNetwork.address;
  } else if (chainId && networks[chainId]) {
    return networks[chainId].address;
  }
  return null;
};

export const useContract = (address: string | null, abi: ContractInterface) => {
  const { library, account } = useWeb3React();

  return useMemo(() => {
    if (!account || !library || !address) {
      return null;
    }

    try {
      const signer = library.getSigner(account);
      const contract = new Contract(address, abi, signer);
      return contract;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to get contract: ${error.message}`);
      } else {
        throw new Error('Failed to get contract: Unknown error');
      }
    }
  }, [address, abi, library, account]);
};

export const useKittyCoreContract = () => {
  const { chainId } = useWeb3React();
  const address = useMemo(() => getNetworkAddress(kittyCore.networks, chainId), [chainId]);
  return useContract(address, kittyCore.abi);
};

export const useMarketPlaceContract = () => {
  const { chainId } = useWeb3React();
  const address = useMemo(() => getNetworkAddress(marketPlace.networks, chainId), [chainId]);
  return useContract(address, marketPlace.abi);
};