import React from "react";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <footer>
      <Container>
        <Grid container>
          <Grid item xs>
            <Typography variant="subtitle1" color="textSecondary" component="p">
              A &nbsp;<Link href="https://mrdr.me" color="secondary" target="_blank" rel="noopener">
          MRDR
        </Link>
        &nbsp;Project - Based on code by Erno Wever
              <br />
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              component="p"
              align="right"
            >
              <Link
                href="https://x.com/MrdrCoin"
                target="_blank"
                rel="noopener"
              >
                <FontAwesomeIcon fixedWidth icon={faTwitter} />
              </Link>{" "}
              <Link
                href="https://t.me/+Lk3PUlUZHG05ZGNh"
                target="_blank"
                rel="noopener"
              >
                <FontAwesomeIcon fixedWidth icon={faTelegram} />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
