import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { ReactComponent as Crows } from "../assets/svg/crows.svg";

export const Home = () => {
  return (
    <div>
      <Typography variant="h4" align="center" color="secondary" gutterBottom>
      DirtyBirdy
      </Typography>

      <Box mx="auto" maxWidth={640} my={7}>
        <Typography align="center">
          Gen0 crows are limited to 420! Mint one in the <a href="/factory">factory</a><br/><br/>
          Collect and breed your own murder of crows with MurderMaker! Each crow has a unique DNA string that
          defines its appearance and traits. Players can breed their crows to
          create new birds and unlock rare attributes.<br/><br/>
          Here's what happens with the CRO from each mint or breed:<br/>
          <ul>
            <li>25% to buy MRDR and distribute to DirtyBirdy holders</li>
            <li>50% to MRDR/CRO LP</li>
            <li>25% to MRDR marketing and development of more projects</li>
          </ul>
          Hop in the &nbsp;
          <Link href="https://mrdr.me" color="secondary" target="_blank" rel="noopener">
          Telegram
        </Link>&nbsp; for more info on this and our other projects.
        </Typography>

        <Box display="flex" mt={6} sx={{ justifyContent: 'center' }}>
          <Crows/>
        </Box>
        <Box display="flex" mt={6} sx={{ justifyContent: 'center' }}>
        </Box>
      </Box>
    </div>
  );
};
