//@ts-ignore
import nearestColor from "nearest-color";
//@ts-ignore
import colorNameList from "color-name-list";
import { CatConfig } from "../components/Cat/Cat";
import { sha256 } from "hash.js";
import { names } from "../assets/data/names";
import { adjectives } from "../assets/data/adjectives";
import { capitalizeFirstLetter } from "./string";

// See https://github.com/meodai/color-names
const colors = colorNameList.reduce(
  (o, { name, hex }) => Object.assign(o, { [name]: hex }),
  {}
);
const nearest = nearestColor.from(colors);

// get closest named color

export const getColorName = (color: string) => {
  return nearest(color).name;
};

const patternNames = [
  null,
  "Zebrahead",
  "Zebrabod",
  "Giraffebod",
  "Houndstoothbod",
  "Flannelbod",
  "Swirlbod",
  "Swirlhead",
  "Spotty",
  "Dotty",
  "Tats",
  "Stripeywings",
  "Stripeybod",
  "Stripeyhead",
  "Stripey",
  "Weedbod",
];
export const getPatternName = (index: number) => {
  return patternNames[index];
};

const eyesName = [
  "Gandhi",
  "Johnny",
  "Cateye",
  "Intense",
  "Dahmer",
  "Jack",
  "Thug",
  "Hoot",
  "Eggs",
  "Round",
  "Beady",
  "Focus",
  "Closed",
  "Lashes",
  "Robin",
  "Quizzical",
  "Meany",
  "Brows",
  "Weary",
  "Surprise",
  "Cute",
  "Disbelief",
  "Heavy",
];
export const getEyeName = (index: number) => {
  return eyesName[index];
};

const decorationNames = [
  "None",
  "Knife",
  "Left Tongue",
  "Right Tongue",
  "Hawk",
  "Spikey",
  "Cronos Chain",
  "MRDR Chain",
  "Phones",
  "Tigers",
  "Dodgers",
  "Yankees",
  "Wolverine",
  "Deadpool",
  "Bats",
  "Spidey",
  "Joint",
];
export const getDecorationName = (index: number) => {
  return decorationNames[index];
};

export const getCatName = (catConfig: CatConfig, id: number) => {
  const dataForNameHash = [
    catConfig.mainColor,
    catConfig.secondaryColor,
    catConfig.patternColor,
    catConfig.mainBaseColor,
    catConfig.secondaryBaseColor,
    catConfig.mainHairColor,
    catConfig.secondaryHairColor,
    catConfig.chainColor,
    catConfig.mainHeadphoneColor,
    catConfig.secondaryHeadphoneColor,
    catConfig.beakShadowColor,
    catConfig.beakColor,
    catConfig.featherColor,
    catConfig.mainEyeColor,
    catConfig.secondaryEyeColor,
    catConfig.eyes,
    catConfig.pattern,
    catConfig.decoration,
    catConfig.hidden1,
    IDBRequest,
  ];
  const dataForAdjectiveHash = [
    catConfig.mainColor,
    catConfig.secondaryColor,
    catConfig.patternColor,
    catConfig.mainBaseColor,
    catConfig.secondaryBaseColor,
    catConfig.mainHairColor,
    catConfig.secondaryHairColor,
    catConfig.chainColor,
    catConfig.mainHeadphoneColor,
    catConfig.secondaryHeadphoneColor,
    catConfig.beakShadowColor,
    catConfig.beakColor,
    catConfig.featherColor,
    catConfig.mainEyeColor,
    catConfig.secondaryEyeColor,
    catConfig.eyes,
    catConfig.pattern,
    catConfig.decoration,
    catConfig.hidden2,
    id,
  ];

  const baseName =
    names[
      parseInt(sha256().update(dataForNameHash).digest("hex"), 16) %
        names.length
    ];
  const adjective =
    adjectives[
      parseInt(sha256().update(dataForAdjectiveHash).digest("hex"), 16) %
        adjectives.length
    ];

  return `${capitalizeFirstLetter(adjective)} ${baseName}`;
};
