export const supportedChains = [
  {
    id: 1337,
    label: "Local development",
  },
  {
    id: 3,
    label: "Ropsten",
  },
  {
    id: 80002,
    label: "Amoy",
  },
  {
    id: 338,
    label: "Cronos Testnet",
  },
  {
    id: 25,
    label: "Cronos Mainnet",
  },
];
